import { CustomCard } from "components/CustomCard";

export const Projects = () => {
  return (
    <div>
      <h1 class="font-medium text-2xl mb-8 tracking-tighter">projects</h1>
      <CustomCard
        title="DuckPay"
        subtitle1="React | DaisyUi | Node.js"
        subtitle2={
          <a
            className="underline"
            href="https://github.com/Donovan9617/duckpay"
          >
            GitHub
          </a>
        }
        description={[
          <p>
            DuckPay is a digital wallet for making and receiving payments by
            using tokens and QR, without the need for bank accounts, credit
            cards, or personal accounts.
          </p>,
        ]}
      />
      <CustomCard
        title="ScamWatch"
        subtitle1="React | Recharts | Bootstrap"
        subtitle2={
          <a
            className="underline"
            href="https://github.com/Donovan9617/scamwatch-frontend"
          >
            GitHub
          </a>
        }
        description={[
          <p>
            ScamWatch is a tool which provides various scam case file management
            features, data visualisation charts, and simple scam statistics.
          </p>,
        ]}
      />
      <CustomCard
        title="SEPlan"
        subtitle1="Django | CSS"
        subtitle2={
          <a className="underline" href="https://github.com/Donovan9617/SEPlan">
            GitHub
          </a>
        }
        description={[
          <p>
            SEPlan is a web app helping students prepare for overseas student
            exchange with event calendar, community reviews, and module mapping.
          </p>,
        ]}
      />
      <CustomCard
        title="DevEnable"
        subtitle1="Java"
        subtitle2={
          <a
            className="underline"
            href="https://github.com/Donovan9617/DevEnable"
          >
            GitHub
          </a>
        }
        description={[
          <p>
            DevEnable is an app using command line interface with task
            management features for software developers to manage their
            projects, issues, and clients.
          </p>,
        ]}
      />
      <CustomCard
        title="Cinnabot"
        subtitle1="Python | Telegram Bot API"
        subtitle2={
          <a
            className="underline"
            href="https://github.com/Donovan9617/Cinnabot"
          >
            GitHub
          </a>
        }
        description={[
          <p>
            Cinnabot is a chatbot serving residents of NUS College, offering
            features for booking common spaces, submitting fund requests, and
            sending feedback to committees.
          </p>,
        ]}
      />
    </div>
  );
};
