import { CustomCard } from "components/CustomCard";

export const Experience = () => {
  return (
    <div>
      <h1 class="font-medium text-2xl mb-8 tracking-tighter">
        software engineering
      </h1>
      <div class="prose prose-neutral dark:prose-invert">
        <CustomCard
          title="OKX"
          subtitle1="Incoming Software Engineer, From July 2025"
          description={[<p>Centralised Finance, Growth team.</p>]}
        />
        <CustomCard
          title="Standard Chartered"
          subtitle1="Software Engineering Intern, May 2024 - August 2024"
          description={[
            <p>
              I interned at{" "}
              <a className="underline" href="https://www.sc.com/">
                Standard Chartered
              </a>{" "}
              with the Corporate & Investment Banking, E-Commerce, Blade team. I
              developed full-stack features on the{" "}
              <a
                className="underline"
                href="https://www.sc.com/en/corporate-investment-banking/markets-platform/"
              >
                Standard Chartered Markets
              </a>{" "}
              application that supports pricing and trading of cross-asset
              derivatives products. I refactored core backend services,
              migrating components from Scala to Java to enhance scalability.
            </p>,
          ]}
        />
        <CustomCard
          title="Shopee"
          subtitle1="Data Engineering Intern, January 2024 - April 2024"
          description={[
            <p>
              I interned at{" "}
              <a className="underline" href="https://shopee.com/">
                Shopee
              </a>{" "}
              with the Marketplace, Regional Sellers team. I designed schemas
              and optimised queries for processing Shopee traffic data for{" "}
              <a className="underline" href="https://seller.shopee.sg/edu/home">
                Seller Education Hub
              </a>{" "}
              and{" "}
              <a
                className="underline"
                href="https://seller.shopee.sg/edu/article/10153/about-seller-missions"
              >
                Seller Missions
              </a>
              , built data pipelines processing sellers' metrics data for{" "}
              <a
                className="underline"
                href="https://seller.shopee.sg/edu/article/7048"
              >
                Preferred Sellers
              </a>
              , and designed regex for detecting seller accounts with fradulent
              shop names.
            </p>,
          ]}
        />
        <CustomCard
          title="HTX"
          subtitle1="Software Engineering Intern, May 2023 - August 2023"
          description={[
            <p>
              I interned at{" "}
              <a className="underline" href="https://www.htx.gov.sg/">
                Home Team Science & Technology Agency
              </a>{" "}
              where I developed full-stack features and built
              infrastructure-as-code deployment architecture on{" "}
              <a
                className="underline"
                href="https://develop.dm6ir8unazrpd.amplifyapp.com/"
              >
                HTX's Cloud Evidence Portal
              </a>{" "}
              to provide digital forensics capabilities for police officers. I
              later returned to work on full-stack development of{" "}
              <a
                className="underline"
                href="https://www.htx.gov.sg/news/featured-news-fighting-the-scourge-of-deepfakes-with-alchemix"
              >
                HTX's AlchemiX 2.0
              </a>
              , a deepfake detection product used by government agencies.
            </p>,
          ]}
        />
        {/* <CustomCard
          title="Tinkertanker"
          subtitle1="Software Engineering Intern, May 2022 - August 2022"
          description={[
            <p>
              I interned at{" "}
              <a className="underline" href="https://tinkertanker.com/">
                Tinkertanker
              </a>{" "}
              where I developed full-stack features on an administration portal
              as well as frontend features on the learning management system{" "}
              <a className="underline" href="https://www.echoclass.com/">
                EchoClass
              </a>
              .
            </p>,
          ]}
        /> */}
        <hr class="my-6 border-neutral-100 dark:border-neutral-800" />

        <h1 class="font-medium text-2xl mb-8 tracking-tighter">teaching</h1>
        <CustomCard
          title="Software Development"
          subtitle1="National University of Singapore, Summer 2023, Summer 2024"
          description={[
            <p>
              I was a teaching assistant for two iterations of{" "}
              <a
                className="underline"
                href="https://nusmods.com/modules/CP2106/independent-software-development-project-orbital"
              >
                CP2106: Software Development
              </a>{" "}
              taught by Professor Zhao Jin. My responsibilities included
              teaching software engineering practices, providing sprint
              consultations and grading submissions.
            </p>,
          ]}
        />
        <CustomCard
          title="Big Data Systems"
          subtitle1="National University of Singapore, Spring 2025"
          description={[
            <p>
              I was a teaching assistant for{" "}
              <a
                className="underline"
                href="https://nusmods.com/courses/CS4225/big-data-systems-for-data-science"
              >
                CS4225: Big Data Systems
              </a>{" "}
              taught by Professor He Bingsheng.
            </p>,
          ]}
        ></CustomCard>
      </div>
    </div>
  );
};
